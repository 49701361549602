.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.p-datatable .p-datatable-header {
  min-width: 60rem;
  width: 400px;
  margin: 0 auto;
}
* .th{
  background: black;
  color: white;
}

ol.progtrckr {
  list-style-type: none;
  padding: 0;
}
ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 4.5rem;
  cursor: pointer;
}
ol.progtrckr li span {
  padding: 0 1.5rem;
}
@media (max-width: 650px) {
  .progtrckr li span {
    display: none;
  }
}
.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}
@media (max-width: 650px) {
  .progtrckr em {
    display: inline;
  }
}
ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
}
ol.progtrckr li.progtrckr-doing {
  color: black;
  border-bottom: 4px solid #CCCCCC;
}
ol.progtrckr li.progtrckr-done {
  color: black;
  border-bottom: 4px solid #5CB85C;
}
ol.progtrckr li:after {
  content: "\00a0\00a0";
}
ol.progtrckr li:before {
  position: relative;
  bottom: -3.7rem;
  float: left;
  left: 50%;
}
ol.progtrckr li.progtrckr-todo:before {
  content: "\039F";
  color: silver;
  background-color: white;
  width: 2.5em;
  line-height: 1.4em;
}
ol.progtrckr li.progtrckr-todo:hover:before {
  color: #FF4500;
}
ol.progtrckr li.progtrckr-doing:before {
  content: "\2022";
  color: white;
  background-color: #CCCCCC;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}
ol.progtrckr li.progtrckr-doing:hover:before {
  color: #FF4500;
}
ol.progtrckr li.progtrckr-done:before {
  content: "\2713";
  color: white;
  background-color: #5CB85C;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
}
ol.progtrckr li.progtrckr-done:hover:before {
  color: #333;
}

.footer-buttons{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.footer-buttons:after {
  content: "";
  clear: both;
  display: table;
}

.footer-buttons button:not(:last-child) {
  border-right: none; /* Prevent double borders */
}

.dropzone {
  min-height: 150px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  background: white;
  padding: 20px 20px;
}
.dzu-inputLabel {
  
  min-height: 100px;
  max-height: 10px;
  width: 100%;
  background-color: rgb(242, 244, 250);
  border: 1px dashed rgb(221, 223, 225);
  overflow: hidden
}
.dzu-inputLabel, .dzu-inputLabelWithFiles {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  align-items: center;
  color: #2484ff;
  cursor: pointer;
  display: flex;
  font-family: Helvetica,sans-serif;
  font-weight: 600;
  justify-content: center;
}
.hr{
  width: 100%;
  height: 20px;
}